@import "~antd/dist/antd.css";
@import "variables.scss";

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

img {
  max-width: 100%;
  max-height: 50%;
}
.top_content_container {
  padding: 16px 24px;
  display: grid;
  grid-template-columns: 5% auto 10%;
  grid-column-gap: 10px;
}
.top_content_publications {
  padding: 16px 24px;
  display: grid;
  grid-template-columns: 5% auto 15%;
  grid-column-gap: 10px;
}

.content_container {
  padding: 16px 24px;
}

// Antd Color
.ant-btn-primary {
  background: $primary-color;
  border-color: $primary-color;
  &:hover,
  &:focus {
    background: $primary-color;
    border-color: $primary-color;
    opacity: 0.8;
  }
}
li.ant-menu-item-selected {
  background-color: rgba(0, 0, 0, 0.4) !important;
}
.ant-pagination-item-active,
.ant-pagination-item-active a {
  color: $primary-color;
  border-color: $primary-color;
}

.ant-menu:not(.ant-menu-horizontal) .ant-menu-item-selected {
  background-color: rgba(25, 148, 121, 0.25) !important;
}
.ant-menu-inline .ant-menu-item:after,
.ant-menu-vertical-left .ant-menu-item:after,
.ant-menu-vertical-right .ant-menu-item:after,
.ant-menu-vertical .ant-menu-item:after {
  border-right: 3px solid #199479 !important;
}

.table-row-dark{
  background-color: #EFEDED;
}
