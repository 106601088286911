.login_form_container {
  margin: 0 auto !important;
  margin-top: 150px !important;
  max-width: 300px;
  text-align: center;
}
.login_form_logo {
  margin-bottom: 40px;
}
.login_form_forgot {
  float: left;
  color: #199479;
}

.space {
  margin-bottom: 10px !important;
}
